export default async function getMenuData() {
  return [
    {
      title: 'Home',
      key: 'home',
      icon: 'fe fe-home',
      url: '/dashboard/main',
    },
    {
      title: 'Proposals',
      key: 'proposals',
      icon: 'fe fe-folder',
      url: '/proposals/view',
    },
    {
      title: 'Contacts',
      key: 'contacts',
      icon: 'fe fe-users',
      url: '/dashboard/main',
      disabled: true,
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings',
      url: '/dashboard/main',
      disabled: true,
    },
  ]
}
