/* eslint-disable */
import React from 'react'
import Search from './Search'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import { Button } from 'antd'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="mr-auto"></div>
      <div className=""></div>
    </div>
  )
}

export default TopBar
